import { Container } from "react-bootstrap";
import styled from "styled-components";

const MaxWidthContainer = styled(Container)`
  max-width: 1440px;
`;

interface ContainerWithMaxWidthProps {
  children: any;
  className?: string;
}

export const ContainerWithMaxWidth = (props: ContainerWithMaxWidthProps) => {
  const { children, className } = props;

  return (
    <MaxWidthContainer className={className} fluid>
      {children}
    </MaxWidthContainer>
  );
};
