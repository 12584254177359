// Map number x from range [a, b] to [c, d]
const map = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c;

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

const calcWinsize = () => {
  return { width: window.innerWidth, height: window.innerHeight };
};

// Gets the mouse position
const getMousePos = (e) => {
  return {
    x: e.clientX,
    y: e.clientY,
  };
};

// Clamps a value between an upper and lower bound
const clamp = (num, min, max) => (num <= min ? min : num >= max ? max : num);

// Map number x from range [a, b] to [c, d]
const mapScroll = (x, a, b, c, d) =>
  clamp(((x - a) * (d - c)) / (b - a) + c, Math.min(c, d), Math.max(c, d));

export { map, lerp, calcWinsize, getMousePos, mapScroll, clamp };
