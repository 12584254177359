import { useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { AppContext } from "../shared/context/app.context";
import { useSmoothScrollWithImageEffect } from "../shared/hooks/use-smooth-scroll-with-image-effect";

const wrapper = (isFirstTransition: boolean) => {
  return {
    animate: {
      transition: {
        staggerChildren: 0.1,
        delayChildren: isFirstTransition ? 2.8 : 0.4,
      },
    },
  };
};

const content = {
  initial: { y: -20, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      ease: [0.6, -0.05, 0.01, 0.99],
    },
  },
};

interface PageProps {
  children: any;
}

export const Page = (props: PageProps) => {
  const { isFirstTransition } = useContext(AppContext);

  useEffect(() => {
    const timeout = setTimeout(useSmoothScrollWithImageEffect, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <motion.section exit={{ opacity: 0 }}>
      <motion.div
        initial="initial"
        animate="animate"
        variants={wrapper(isFirstTransition)}
      >
        <motion.div variants={content}>{props.children}</motion.div>
      </motion.div>
    </motion.section>
  );
};
