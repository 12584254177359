import { Col, Row } from "react-bootstrap";
import { ContainerWithMaxWidth } from "./ContainerWithMaxWidth";
import styled from "styled-components";
import Dennis from "../assets/images/dennis_politschke_visual_designer.jpg";
import { calculateFontSize } from "../shared/helper";
import { Hyperlink } from "./Hyperlink";

const Figure = styled.figure`
  position: relative;
  padding-top: 40px;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    padding-top: 85px;
  }
`;

const Title = styled.h2`
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;

  line-height: 1.3em;
  ${calculateFontSize(50, 130)};

  span {
    display: block;
    text-transform: uppercase;

    &.first {
      @media screen and (min-width: 992px) {
        padding-left: 128px;
      }
    }

    &.second {
      padding-left: 25px;

      @media screen and (min-width: 992px) {
        padding-left: 232px;
      }
    }
  }

  em {
    font-family: "PlayfairDisplay";
    text-transform: none;
  }

  .mobile-only {
    display: none;

    @media screen and (max-width: 992px) {
      display: block;
    }
  }
`;

const DesktopOnlyPaddingCol = styled(Col)`
  @media screen and (max-width: 992px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const FlexEndCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const LinkList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  width: 215px;
  text-align: left;
  padding-top: 30px;
  margin-bottom: 0;

  li {
    text-transform: uppercase;
    flex-basis: 50%;
    font-weight: 600;
    font-size: 14px;
  }
`;

const About = styled.p`
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 30px;

  em {
    font-family: "PlayfairDisplay";
    text-transform: none;
  }
`;

const InlineLink = styled(Hyperlink)`
  display: inline;
  font-size: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

const ImgWrap = styled.div`
  width: 100%;
  max-width: 345px;
  height: 547px;

  @media screen and (min-width: 768px) {
    max-width: 710px;
    height: 878px;
  }
`;

export const DennisPolitschke = () => {
  return (
    <ContainerWithMaxWidth>
      <Row>
        <DesktopOnlyPaddingCol>
          <Figure>
            <div className="content">
              <div className="content__item">
                <ImgWrap className="content__item-imgwrap">
                  <div
                    className="content__item-img"
                    style={{ backgroundImage: `url(${Dennis})` }}
                  ></div>
                </ImgWrap>
                <Title>
                  <span className="first">Visual</span>
                  <span className="second">
                    Designer <em>aus</em>
                  </span>
                  <span>
                    Mönchen
                    <br className="mobile-only" />
                    gladbach
                  </span>
                </Title>
              </div>
            </div>
            <FlexEndCol
              xs={{ span: 8, offset: 4 }}
              md={{ span: 6, offset: 5 }}
              lg={{ span: 6, offset: 3 }}
            >
              <LinkList>
                <li>
                  <Hyperlink
                    href="https://dribbble.com/Qwerbeet"
                    isExternal
                    withIcon
                    label="Dribble"
                  />
                </li>
                <li>
                  <Hyperlink
                    href="https://de.linkedin.com/in/dennis-politschke-26617211a"
                    isExternal
                    withIcon
                    label="LinkedIn"
                  />
                </li>
                <li>
                  <Hyperlink
                    href="https://www.instagram.com/qwerbeat/?hl=de"
                    isExternal
                    withIcon
                    label="Instagram"
                  />
                </li>
                <li>
                  <Hyperlink
                    href="https://www.xing.com/profile/Dennis_Politschke"
                    isExternal
                    withIcon
                    label="XING"
                  />
                </li>
              </LinkList>
            </FlexEndCol>
          </Figure>
        </DesktopOnlyPaddingCol>
      </Row>
      <Row id="about">
        <Col xs={12} md={6} lg={{ span: 3, offset: 2 }}>
          <About>
            MEIN NAME <em>ist</em> DENNIS, ICH BIN 28 JAHRE <em>alt</em> UND
            WOHNE <em>in</em> MÖNCHENGLADBACH. NAJA, EIGENTLICH KOMME{" "}
            <em>ich</em> AUS EINEM KLEINEN DORF IN DER NÄHE <em>von</em>{" "}
            BIELEFELD. ABER DA ES BIELEFELD <em>ja</em> NICHT GIBT... ACH KOMM,
            LASSEN WIR <em>das</em>.
          </About>
          <About>
            ZUR ZEIT ARBEITE <em>ich</em> BEI DER SUM.CUMO GmBH <em>in</em>{" "}
            DÜSSELDORF. FALLS DU MEHR WISSEN <em>willst</em>, CHECK MEINE{" "}
            <InlineLink href="/vita" withUnderline label="Vita" />.
          </About>
        </Col>
      </Row>
    </ContainerWithMaxWidth>
  );
};
