export const calculateFontSize = (minFontSize: number, maxFontSize: number) => {
    return `
        font-size: ${minFontSize}px;
        
        @media (min-width: 375px) and (max-width: 1440px) {
            font-size: calc(${minFontSize}px + (${maxFontSize} - ${minFontSize}) * (100vw - 375px) / (1440 - 375));
        }
        
        /* Define max font size */
        @media (min-width: 1441px) {
            font-size: ${maxFontSize}px;
        }
    `;
}
