import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledExternalLink = styled.a`
  font-size: 14px;
  color: #de5050;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;

  svg {
    margin-right: 10px;
  }

  text-decoration: none;

  &:hover {
    color: #c13535;
    text-decoration: none;
  }

  ${(props) => {
    if (props.theme.withUnderline) {
      return `
      text-decoration: underline

      :hover {
        text-decoration: underline
      }
      `;
    }
  }}
`;

StyledExternalLink.defaultProps = {
  theme: {
    withUnderline: false,
  },
};

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #de5050;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;

  svg {
    margin-right: 10px;
  }

  text-decoration: none;

  &:hover {
    color: #c13535;
    text-decoration: none;
  }

  ${(props) => {
    if (props.theme.withUnderline) {
      return `
      text-decoration: underline

      :hover {
        text-decoration: underline
      }
      `;
    }
  }}
`;

StyledLink.defaultProps = {
  theme: {
    withUnderline: false,
  },
};

interface HyperlinkProps {
  className?: string;
  href: string;
  label: string;
  withIcon?: boolean;
  withUnderline?: boolean;
  isExternal?: boolean;
}

export const Hyperlink = (props: HyperlinkProps) => {
  const { className, href, label, withIcon, withUnderline, isExternal } = props;

  return isExternal ? (
    <StyledExternalLink
      className={className}
      theme={{ withUnderline }}
      href={href}
      target="_blank"
    >
      {withIcon ? (
        <>
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.17604 1.65333L2.24029 1.65564L2.239 0.00452705L7.99549 0L8 5.7789L6.35529 5.78019L6.35297 2.80691L1.16083 8L0 6.83033L5.17604 1.65333Z"
              fill="white"
            />
          </svg>
          {label}
        </>
      ) : (
        <span>{label}</span>
      )}
    </StyledExternalLink>
  ) : (
    <StyledLink className={className} theme={{ withUnderline }} to={href}>
      {withIcon ? (
        <>
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.17604 1.65333L2.24029 1.65564L2.239 0.00452705L7.99549 0L8 5.7789L6.35529 5.78019L6.35297 2.80691L1.16083 8L0 6.83033L5.17604 1.65333Z"
              fill="white"
            />
          </svg>
          {label}
        </>
      ) : (
        <span>{label}</span>
      )}
    </StyledLink>
  );
};
