import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { ContainerWithMaxWidth } from "../components/ContainerWithMaxWidth";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Page } from "./Page";

import Huelsta from "../assets/images/projects/huelsta.jpg";
import DayliUi from "../assets/images/projects/dayliui.jpg";
import StellDichQuer from "../assets/images/projects/stell-dich-quer.jpg";
import Steelworks from "../assets/images/projects/steelworks.jpg";
import RoteKarte from "../assets/images/projects/rote-karte.jpg";
import { Hyperlink } from "../components/Hyperlink";
import { calculateFontSize } from "../shared/helper";

const ContainerWithSpace = styled(ContainerWithMaxWidth)`
  padding-top: 40px;
  padding-bottom: 40px;

  @media screen and (min-width: 992px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const ProjectItem = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 30px 0;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 768px) {
    padding-top: 60px;
  }

  &.first {
    padding-top: 0 !important;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    margin-top: 40px;
  }

  p {
    margin-bottom: 0;
    width: calc(100% - 90px);
    margin-right: 60px;
  }

  a {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

const Title = styled.h2`
  position: relative;
  z-index: 100;

  line-height: 1.3em;
  ${calculateFontSize(50, 130)};

  span {
    display: block;
    text-transform: uppercase;

    &.first {
      text-align: center;
    }

    &.second {
      text-align: left;
    }

    &.third {
      text-align: right;
    }
  }

  em {
    font-family: "PlayfairDisplay";
    text-transform: none;
  }

  .mobile-only {
    display: none;

    @media screen and (max-width: 992px) {
      display: block;
    }
  }
`;

export const Projects = () => {
  return (
    <Page>
      <Header />
      <ContainerWithMaxWidth>
        <Row>
          <Col xs={12}>
            <Title>
              <span className="first">Projekte</span>
              <span className="second">
                projekte <em>projekte</em>
              </span>
              <span className="third">Projekte</span>
            </Title>
          </Col>
        </Row>
      </ContainerWithMaxWidth>
      <ContainerWithSpace>
        <Row>
          <Col xs={12} md={{ offset: 1, span: 5 }}>
            <ProjectItem className="first">
              <img src={Huelsta} alt="" />
              <DescriptionContainer>
                <p>Relaunch Pitch für hülsta</p>
                <Hyperlink
                  href="/projects/huelsta"
                  withIcon
                  label="Zeig mal!"
                />
              </DescriptionContainer>
            </ProjectItem>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ offset: 3, span: 5 }}>
            <ProjectItem>
              <img src={DayliUi} alt="" />
              <DescriptionContainer>
                <p>Dayli UI Challenge</p>
                <Hyperlink
                  href="/projects/dayli-ui"
                  withIcon
                  label="Zeig mal!"
                />
              </DescriptionContainer>
            </ProjectItem>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ offset: 6, span: 5 }}>
            <ProjectItem>
              <img src={StellDichQuer} alt="" />
              <DescriptionContainer>
                <p>
                  Stell dich Quer - Eine Kampagne gegen Rechtextremismus in
                  Deutschland
                </p>
                <Hyperlink
                  href="/projects/stell-dich-quer"
                  withIcon
                  label="Zeig mal!"
                />
              </DescriptionContainer>
            </ProjectItem>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ offset: 1, span: 5 }}>
            <ProjectItem>
              <img src={Steelworks} alt="" />
              <DescriptionContainer>
                <p>Steelworks - by thyssenkrupp</p>
                <Hyperlink
                  href="/projects/steelworks"
                  withIcon
                  label="Zeig mal!"
                />
              </DescriptionContainer>
            </ProjectItem>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ offset: 3, span: 5 }}>
            <ProjectItem>
              <img src={RoteKarte} alt="" />
              <DescriptionContainer>
                <p>Rote Karte - by Bayer AG</p>
                <Hyperlink
                  href="/projects/rote-karte"
                  withIcon
                  label="Zeig mal!"
                />
              </DescriptionContainer>
            </ProjectItem>
          </Col>
        </Row>
      </ContainerWithSpace>
      <Footer />
    </Page>
  );
};
