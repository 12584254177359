import { DennisPolitschke } from "../components/DennisPolitschke";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { ProjectsStrip } from "../components/ProjectsStrip";
import { Page } from "./Page";

export const Portfolio = () => {
  return (
    <Page>
      <Header />
      <DennisPolitschke />
      <ProjectsStrip />
      <Footer />
    </Page>
  );
};
