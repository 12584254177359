import styled from "styled-components";
import { Hyperlink } from "./Hyperlink";

const StripItem = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 35px;

  @media screen and (min-width: 768px) {
    margin-right: 70px;
  }
`;

const ImgOuter = styled.div`
  width: 100vw;
  height: 220px;
  max-width: 325px;
  overflow: hidden;
  position: relative;
  flex: none;

  @media screen and (min-width: 768px) {
    height: 395px;
    max-width: 590px;
  }
`;

const ImgInner = styled.div`
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  left: -20px;
  top: -20px;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
`;

const DescriptionContainer = styled.span`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    margin-top: 40px;
  }

  p {
    margin-bottom: 0;
    width: calc(100% - 90px);
    margin-right: 60px;
  }

  a {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

interface ProjectsStripItemProps {
  imageUrl: string;
  text: string;
  projectLink: string;
}

export const ProjectsStripItem = (props: ProjectsStripItemProps) => {
  const { imageUrl, text, projectLink } = props;
  return (
    <StripItem className="strip__item">
      <ImgOuter className="img-outer">
        <ImgInner
          className="img-inner"
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      </ImgOuter>
      <DescriptionContainer>
        <p>{text}</p>
        <Hyperlink
          className="strip__item-link"
          href={projectLink}
          withIcon
          label="Zeig mal!"
        />
      </DescriptionContainer>
    </StripItem>
  );
};
