import { ContainerWithMaxWidth } from "../components/ContainerWithMaxWidth";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { calculateFontSize } from "../shared/helper";
import { ProjectsStrip } from "../components/ProjectsStrip";
import { Page } from "./Page";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

import HeroImage from "../assets/images/huelsta/1.png";
import DetailLeft from "../assets/images/huelsta/2.png";
import DetailRight from "../assets/images/huelsta/3.png";
import DetailBottom from "../assets/images/huelsta/4.png";

const Hero = styled.figure`
  position: relative;
  padding-top: 40px;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    padding-top: 85px;
  }
`;

const Title = styled.h2`
  position: relative;
  margin-top: -80px;
  z-index: 100;

  line-height: 1.3em;
  ${calculateFontSize(50, 130)};

  span {
    display: block;
    text-transform: uppercase;

    &.first {
      text-align: center;
    }

    &.second {
      text-align: left;
    }

    &.third {
      text-align: right;
    }
  }

  em {
    font-family: "PlayfairDisplay";
    text-transform: none;
  }

  .mobile-only {
    display: none;

    @media screen and (max-width: 992px) {
      display: block;
    }
  }
`;

const ImgWrap = styled.div`
  width: 100%;
  max-width: 600px;
  height: 217px;

  @media screen and (min-width: 768px) {
    max-width: 1200px;
    height: 517px;
  }

  &.with-max-width {
    img {
      width: 100%;
    }
  }
`;

const ColImg = styled.div`
  img {
    width: 100%;
  }
`;

const RightCol = styled(Col)`
  padding-top: 50px;

  @media screen and (min-width: 768px) {
    padding-top: 200px;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 500px;
  }
`;

const ContainerWithSpaceBottom = styled(ContainerWithMaxWidth)`
  margin-bottom: 40px;

  @media screen and (min-width: 992px) {
    margin-bottom: 85px;
  }
`;

const BottomImg = styled(Col)`
  display: flex;
  justify-content: center;

  padding-top: 50px;
  margin-bottom: 50px;

  @media screen and (min-width: 768px) {
    padding-top: 100px;
    margin-bottom: 100px;
  }
`;

export const Huelsta = () => {
  return (
    <Page>
      <Header />
      <div className="content">
        <ContainerWithMaxWidth>
          <Row>
            <Col>
              <Hero>
                <div className="content__item">
                  <ImgWrap className="content__item-imgwrap">
                    <div
                      className="content__item-img"
                      style={{ backgroundImage: `url(${HeroImage})` }}
                    ></div>
                  </ImgWrap>
                  <Title>
                    <span className="first">Relaunch Pitch</span>
                    <span className="second">
                      for <em>hülsta</em>
                    </span>
                    <span className="third">UI / UX Design</span>
                  </Title>
                </div>
              </Hero>
            </Col>
          </Row>
        </ContainerWithMaxWidth>
        <ContainerWithSpaceBottom>
          <Row>
            <Col xs={12} md={6} lg={5}>
              <ColImg>
                <img
                  className="deco-img"
                  src={DetailLeft}
                  alt="Relaunch Pitch for hülsta"
                />
              </ColImg>
            </Col>
            <RightCol xs={12} md={6} lg={{ offset: 1, span: 5 }}>
              <ColImg>
                <img
                  className="deco-img"
                  src={DetailRight}
                  alt="Relaunch Pitch for hülsta"
                />
              </ColImg>
            </RightCol>
            <BottomImg xs={12}>
              <ImgWrap className="with-max-width">
                <img
                  className="deco-img"
                  src={DetailBottom}
                  alt="Relaunch Pitch for hülsta"
                />
              </ImgWrap>
            </BottomImg>
          </Row>
        </ContainerWithSpaceBottom>
        <ProjectsStrip />
      </div>
      <Footer />
    </Page>
  );
};
