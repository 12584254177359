import StellDichQuer1 from "../assets/images/stell-dich-quer/stell-dich-quer_hero.jpg";
import StellDichQuer2 from "../assets/images/stell-dich-quer/stell-dich-quer_detail1.jpg";
import StellDichQuer3 from "../assets/images/stell-dich-quer/stell-dich-quer_detail2.jpg";
import StellDichQuer4 from "../assets/images/stell-dich-quer/stell-dich-quer_detail3.jpg";
import { ContainerWithMaxWidth } from "../components/ContainerWithMaxWidth";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { calculateFontSize } from "../shared/helper";
import { ProjectsStrip } from "../components/ProjectsStrip";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Page } from "./Page";

const RelativeRow = styled(Row)`
  position: relative;
`;

const Hero = styled.figure`
  position: relative;
  padding-top: 40px;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    padding-top: 85px;
  }
`;

const Title = styled(Col)`
  position: absolute;
  bottom: 40px;
  left: 0;
  z-index: 100;

  @media screen and (min-width: 768px) {
    bottom: 80px;
  }

  h2 {
    line-height: 1.3em;
    ${calculateFontSize(50, 130)};

    span {
      display: block;
      text-transform: uppercase;

      &.first {
        text-align: center;
      }

      &.second {
        text-align: left;
      }

      &.third {
        text-align: right;
      }
    }

    em {
      font-family: "PlayfairDisplay";
      text-transform: none;
    }

    .mobile-only {
      display: none;

      @media screen and (max-width: 992px) {
        display: block;
      }
    }
  }
`;

const ImgWrap = styled.div`
  width: 100%;
  height: 615px;

  @media screen and (min-width: 588px) {
    max-width: 588px;
    height: 972px;
  }
`;

const ColImg = styled.div`
  img {
    width: 100%;
  }
`;

const ContainerWithSpaceBottom = styled(ContainerWithMaxWidth)`
  margin-bottom: 40px;

  @media screen and (min-width: 992px) {
    margin-bottom: 85px;
  }
`;

const ColWithPadding = styled(Col)`
  padding-top: 50px;

  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }
`;

export const StellDichQuer = () => {
  return (
    <Page>
      <Header />
      <div className="content">
        <ContainerWithMaxWidth>
          <RelativeRow>
            <Col
              xs={12}
              md={{ offset: 2, span: 10 }}
              lg={{ offset: 6, span: 6 }}
            >
              <Hero>
                <div className="content__item">
                  <ImgWrap className="content__item-imgwrap">
                    <div
                      className="content__item-img"
                      style={{ backgroundImage: `url(${StellDichQuer1})` }}
                    ></div>
                  </ImgWrap>
                </div>
              </Hero>
            </Col>
            <Title xs={12}>
              <h2>
                <span className="first">Stell dich quer</span>
                <span className="second">
                  by <em>webArtig</em>
                </span>
                <span className="third">CI / UX / UI</span>
              </h2>
            </Title>
          </RelativeRow>
        </ContainerWithMaxWidth>
        <ContainerWithSpaceBottom>
          <Row>
            <ColWithPadding xs={12} md={8}>
              <ColImg>
                <img src={StellDichQuer2} alt="Stell dich quer by webArtig" />
              </ColImg>
            </ColWithPadding>
            <ColWithPadding xs={12} md={{ span: 8, offset: 4 }}>
              <ColImg>
                <img src={StellDichQuer3} alt="Stell dich quer by webArtig" />
              </ColImg>
            </ColWithPadding>
            <ColWithPadding xs={12} md={8}>
              <ColImg>
                <img src={StellDichQuer4} alt="Stell dich quer by webArtig" />
              </ColImg>
            </ColWithPadding>
          </Row>
        </ContainerWithSpaceBottom>
        <ProjectsStrip />
      </div>
      <Footer />
    </Page>
  );
};
