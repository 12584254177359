import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { ContainerWithMaxWidth } from "../components/ContainerWithMaxWidth";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { calculateFontSize } from "../shared/helper";
import { Page } from "./Page";

const VitaItem = styled(Row)`
  padding: 40px 0;
  border-bottom: 1px solid #171717;

  @media screen and (min-width: 768px) {
    padding: 80px 0;
  }

  &.last {
    border-bottom: none;
  }
`;

const Timespan = styled.p`
  ${calculateFontSize(50, 130)};
  font-weight: 500;
  text-transform: uppercase;
`;

const Description = styled.p`
  text-transform: uppercase;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;

  span {
    display: block;
  }
`;

export const Vita = () => {
  return (
    <Page>
      <Header />
      <ContainerWithMaxWidth>
        <VitaItem>
          <Col xs={12}>
            <Timespan>2020 - Heute</Timespan>
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 4 }}>
            <Description>
              Visual Designer -<span>sum.cumo GmbH</span>
            </Description>
          </Col>
        </VitaItem>
        <VitaItem>
          <Col xs={12}>
            <Timespan>2019 - 2020</Timespan>
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 4 }}>
            <Description>
              UX / UI Designer -<span>webArtig GmbH</span>
            </Description>
          </Col>
        </VitaItem>
        <VitaItem>
          <Col xs={12}>
            <Timespan>2016-2019</Timespan>
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 4 }}>
            <Description>
              UX / UI Designer / Unit Manager -<span>Braintags GmbH</span>
            </Description>
          </Col>
        </VitaItem>
        <VitaItem>
          <Col xs={12}>
            <Timespan>2016 - 2016</Timespan>
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 4 }}>
            <Description>
              Webdesigner -<span>Euroweb GmbH</span>
            </Description>
          </Col>
        </VitaItem>
        <VitaItem className="last">
          <Col xs={12}>
            <Timespan>2013 - 2016</Timespan>
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 4 }}>
            <Description>
              Ausbildung Mediengestalter Digital -<span>Euroweb GmbH</span>
            </Description>
          </Col>
        </VitaItem>
      </ContainerWithMaxWidth>
      <Footer />
    </Page>
  );
};
