import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { ContainerWithMaxWidth } from "./ContainerWithMaxWidth";
import { Hyperlink } from "./Hyperlink";

const FlexEndCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const About = styled.p`
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 30px;

  em {
    font-family: "PlayfairDisplay";
    text-transform: none;
  }

  a {
    text-decoration: underline;
  }
`;

const InlineLink = styled(Hyperlink)`
  display: inline;
  font-size: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

export const Footer = () => {
  return (
    <ContainerWithMaxWidth>
      <Row>
        <FlexEndCol
          xs={{ span: 8, offset: 4 }}
          md={{ span: 6, offset: 5 }}
          lg={{ span: 3, offset: 7 }}
        >
          <About>
            FALLS DIR DER KRAM <em>hier</em> GEFÄLLT, <em>schreib</em> MIR DOCH
            GERNE <em>eine</em>{" "}
            <InlineLink
              href="mailto:dennis.politschke@yahoo.de"
              withUnderline
              isExternal
              label="E-Mail"
            />
            .
          </About>
        </FlexEndCol>
      </Row>
    </ContainerWithMaxWidth>
  );
};
