import { useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { calculateFontSize } from "../shared/helper";
import { ContainerWithMaxWidth } from "./ContainerWithMaxWidth";
import { ProjectsStripItem } from "./ProjectsStripItem";

import Steelworks from "../assets/images/steelworks.jpg";
import StellDichQuer from "../assets/images/stell-dich-quer.jpg";
import { Strip } from "../shared/services/strip";

import DailyUi from "../assets/images/strip/daily-ui.png";
import Huelsta from "../assets/images/strip/huelsta.png";
import RoteKarte from "../assets/images/strip/rote-karte.png";

const Headline = styled.h2`
  line-height: 1.3em;
  ${calculateFontSize(50, 130)};

  text-transform: uppercase;

  em {
    display: block;
    font-family: "PlayfairDisplay";
    text-transform: none;
    padding-left: 150px;

    @media screen and (min-width: 992px) {
      padding-left: 50vw;
    }

    @media screen and (min-width: 1440px) {
      padding-left: 730px;
    }
  }
`;

const StripCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #de5050;
  pointer-events: none;
  opacity: 0;
`;

const StripCoverTitle = styled.h2`
  font-family: "PlayfairDisplay", sans-serif;
  font-weight: 500;
  margin: 0;
  line-height: 1;
  color: #de5050;
  text-transform: lowercase;

  ${calculateFontSize(40, 150)};
`;

export const ProjectsStrip = () => {
  const stripRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!stripRef.current) {
      return;
    }

    new Strip(stripRef.current);
  }, [stripRef]);

  return (
    <>
      <ContainerWithMaxWidth>
        <Row>
          <Headline data-splitting="">
            Ausgewählte
            <em data-splitting="">arbeiten</em>
          </Headline>
        </Row>
      </ContainerWithMaxWidth>
      <div ref={stripRef} className="strip-outer">
        <div className="strip-inner">
          <div className="draggable"></div>
          <div className="strip">
            <ProjectsStripItem
              imageUrl={Steelworks}
              projectLink="/projects/steelWorks"
              text="STEELWORKS - by thyssenkrupp"
            />
            <ProjectsStripItem
              imageUrl={StellDichQuer}
              projectLink="/projects/stell-dich-quer"
              text="Stell Dich Quer - Eine Kampagne gegen Rechtextremismus in Deutschland"
            />
            <ProjectsStripItem
              imageUrl={Huelsta}
              projectLink="/projects/huelsta"
              text="Relaunch Pitch für hülsta"
            />
            <ProjectsStripItem
              imageUrl={DailyUi}
              projectLink="/projects/dayli-ui"
              text="Daily UI - for me myself and I"
            />
            <ProjectsStripItem
              imageUrl={RoteKarte}
              projectLink="/projects/rote-karte"
              text="Rote Karte für bayer ag"
            />
          </div>
        </div>
        <StripCover className="strip-cover">
          <StripCoverTitle className="strip-cover__title">
            Nice to meet you
          </StripCoverTitle>
        </StripCover>
      </div>
    </>
  );
};
