import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Portfolio } from "./pages/Portfolio";
import { Steelworks } from "./pages/Steelworks";
import { CustomCursor } from "./components/CustomCursor";
import { ScrollToTop } from "./components/ScrollToTop";
import { Vita } from "./pages/Vita";
import { StellDichQuer } from "./pages/StellDichQuer";
import { Huelsta } from "./pages/Huelsta";
import { InitialTransition } from "./components/InitialTransition";
import { useState } from "react";
import { AppContext } from "./shared/context/app.context";
import { RoteKarte } from "./pages/RoteKarte";
import { DayliUi } from "./pages/DayliUi";
import { Projects } from "./pages/Projects";
import { AnimatePresence } from "framer-motion";

export const App = () => {
  const [isFirstTransition, setIsFirstTransition] = useState(true);

  return (
    <AppContext.Provider value={{ isFirstTransition, setIsFirstTransition }}>
      <InitialTransition />
      <main>
        <div data-scroll>
          <Router>
            <Switch>
              <Route key="/" exact path="/">
                <AnimatePresence exitBeforeEnter>
                  <Portfolio />
                </AnimatePresence>
              </Route>
              <Route key="/vita" exact path="/vita">
                <AnimatePresence exitBeforeEnter>
                  <Vita />
                </AnimatePresence>
              </Route>
              <Route key="/projects" exact path="/projects">
                <AnimatePresence exitBeforeEnter>
                  <Projects />
                </AnimatePresence>
              </Route>
              <Route
                key="/projects/steelWorks"
                exact
                path="/projects/steelWorks"
              >
                <AnimatePresence exitBeforeEnter>
                  <Steelworks />
                </AnimatePresence>
              </Route>
              <Route
                key="/projects/stell-dich-quer"
                exact
                path="/projects/stell-dich-quer"
              >
                <AnimatePresence exitBeforeEnter>
                  <StellDichQuer />
                </AnimatePresence>
              </Route>
              <Route key="/projects/huelsta" exact path="/projects/huelsta">
                <AnimatePresence exitBeforeEnter>
                  <Huelsta />
                </AnimatePresence>
              </Route>
              <Route
                key="/projects/rote-karte"
                exact
                path="/projects/rote-karte"
              >
                <AnimatePresence exitBeforeEnter>
                  <RoteKarte />
                </AnimatePresence>
              </Route>
              <Route key="/projects/dayli-ui" exact path="/projects/dayli-ui">
                <AnimatePresence exitBeforeEnter>
                  <DayliUi />
                </AnimatePresence>
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
            <ScrollToTop />
          </Router>
        </div>
        <CustomCursor />
      </main>
    </AppContext.Provider>
  );
};
