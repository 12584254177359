import { motion } from "framer-motion";
import styled from "styled-components";

const blackBox = {
  initial: {
    height: "100vh",
    bottom: 0,
  },
  animate: {
    height: 0,
    transition: {
      when: "afterChildren",
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const textContainer = {
  initial: {
    opacity: 1,
  },
  animate: {
    opacity: 0,
    transition: {
      duration: 0.25,
      when: "afterChildren",
    },
  },
};

const text = {
  initial: {
    y: 40,
  },
  animate: {
    y: 80,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const InitialTransition = () => {
  return (
    <Container>
      <motion.div
        className="initial"
        initial="initial"
        animate="animate"
        variants={blackBox}
        onAnimationStart={() => document.body.classList.add("overflow-hidden")}
        onAnimationComplete={() =>
          document.body.classList.remove("overflow-hidden")
        }
      >
        <motion.svg variants={textContainer} className="svg">
          <pattern
            id="pattern"
            patternUnits="userSpaceOnUse"
            width={750}
            height={800}
            className="pattern"
          >
            <rect className="patternRect" />
            <motion.rect variants={text} className="patternMotionRect" />
          </pattern>
          <text
            className="svgText"
            textAnchor="middle"
            x="50%"
            y="50%"
            style={{ fill: "url(#pattern)" }}
          >
            <tspan x="50%" dy="1.2em">
              Dennis Politschke
            </tspan>
            <tspan className="svgTextScd" x="50%" dy="1.2em">
              Visual Designer
            </tspan>
          </text>
        </motion.svg>
      </motion.div>
    </Container>
  );
};
