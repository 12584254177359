import { ContainerWithMaxWidth } from "../components/ContainerWithMaxWidth";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { calculateFontSize } from "../shared/helper";
import { ProjectsStrip } from "../components/ProjectsStrip";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Page } from "./Page";

import HeroImage from "../assets/images/dailyui/1.png";
import Detail1 from "../assets/images/dailyui/2.png";
import Detail2 from "../assets/images/dailyui/3.png";
import Detail3 from "../assets/images/dailyui/4.png";
import Detail4 from "../assets/images/dailyui/5.png";
import Detail5 from "../assets/images/dailyui/6.png";
import Detail6 from "../assets/images/dailyui/7.png";
import Detail7 from "../assets/images/dailyui/8.png";
import Detail8 from "../assets/images/dailyui/9.png";
import Detail9 from "../assets/images/dailyui/10.png";
import Detail10 from "../assets/images/dailyui/11.png";
import Detail11 from "../assets/images/dailyui/12.png";
import Detail12 from "../assets/images/dailyui/13.png";

const RelativeRow = styled(Row)`
  position: relative;
`;

const Hero = styled.figure`
  position: relative;
  padding-top: 40px;
  overflow: hidden;

  @media screen and (min-width: 992px) {
    padding-top: 85px;
  }
`;

const Title = styled(Col)`
  position: absolute;
  bottom: -60px;
  left: 0;
  z-index: 100;

  h2 {
    line-height: 1.3em;
    ${calculateFontSize(50, 130)};

    span {
      display: block;
      text-transform: uppercase;

      &.first {
        text-align: center;
      }

      &.second {
        text-align: left;
      }

      &.third {
        text-align: right;
      }
    }

    em {
      font-family: "PlayfairDisplay";
      text-transform: none;
    }

    .mobile-only {
      display: none;

      @media screen and (max-width: 992px) {
        display: block;
      }
    }
  }
`;

const ImgWrap = styled.div`
  width: 100%;
  height: 615px;

  @media screen and (min-width: 588px) {
    max-width: 710px;
    height: 855px;
  }
`;

const ColWithSmallPadding = styled(Col)`
  padding-top: 40px;

  @media screen and (min-width: 992px) {
    padding-top: 80px;
  }
`;

const ColWithBigPadding = styled(Col)`
  padding-top: 50px;

  @media screen and (min-width: 768px) {
    padding-top: 150px;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 400px;
  }
`;

const ContainerWithPadding = styled(ContainerWithMaxWidth)`
  padding-top: 40px;
  padding-bottom: 40px;

  @media screen and (min-width: 992px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const ColImg = styled.div`
  img {
    width: 100%;
  }
`;

export const DayliUi = () => {
  return (
    <Page>
      <Header />
      <div className="content">
        <ContainerWithMaxWidth>
          <RelativeRow>
            <Col xs={12} md={{ span: 10 }} lg={{ span: 6 }}>
              <Hero>
                <div className="content__item">
                  <ImgWrap className="content__item-imgwrap">
                    <div
                      className="content__item-img"
                      style={{ backgroundImage: `url(${HeroImage})` }}
                    ></div>
                  </ImgWrap>
                </div>
              </Hero>
            </Col>
            <Title xs={12}>
              <h2>
                <span className="first">Dayli Ui</span>
                <span className="second">
                  for me <em>myself</em> and I
                </span>
                <span className="third">UI / UX Design</span>
              </h2>
            </Title>
          </RelativeRow>
        </ContainerWithMaxWidth>
        <ContainerWithPadding>
          <Row>
            <ColWithSmallPadding xs={12} md={6} lg={{ span: 5 }}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail1}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithSmallPadding>
            <ColWithBigPadding xs={12} md={6} lg={{ span: 5, offset: 2 }}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail2}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithBigPadding>
            <ColWithSmallPadding xs={12}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail3}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithSmallPadding>
            <ColWithBigPadding xs={12} md={6} lg={{ span: 5 }}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail5}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithBigPadding>
            <ColWithSmallPadding xs={12} md={6} lg={{ span: 5, offset: 2 }}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail4}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithSmallPadding>
            <ColWithSmallPadding
              xs={12}
              md={{ offset: 4, span: 6 }}
              lg={{ span: 6, offset: 4 }}
            >
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail6}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithSmallPadding>
            <ColWithSmallPadding xs={12} md={6} lg={{ span: 5 }}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail7}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithSmallPadding>
            <ColWithBigPadding xs={12} md={6} lg={{ span: 5, offset: 2 }}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail8}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithBigPadding>
            <ColWithSmallPadding xs={12}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail9}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithSmallPadding>
            <ColWithBigPadding xs={12} md={6} lg={{ span: 5 }}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail11}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithBigPadding>
            <ColWithSmallPadding xs={12} md={6} lg={{ span: 5, offset: 2 }}>
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail10}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithSmallPadding>
            <ColWithSmallPadding
              xs={12}
              md={{ offset: 4, span: 6 }}
              lg={{ span: 6, offset: 4 }}
            >
              <ColImg>
                <img
                  className="deco-img"
                  src={Detail12}
                  alt="Dayli UI - for me myself and I"
                />
              </ColImg>
            </ColWithSmallPadding>
          </Row>
        </ContainerWithPadding>
        {/* <ContainerWithSpaceBottom fluid>
          <Row>
            <ColWithMobilePadding xs={12} md={6} lg={5}>
              <ColImg>
                <img src={StellDichQuer2} alt="Stell dich quer by webArtig" />
              </ColImg>
            </ColWithMobilePadding>
            <RightCol xs={12} md={6} lg={{ offset: 1, span: 5 }}>
              <ColImg>
                <img src={StellDichQuer3} alt="Stell dich quer by webArtig" />
              </ColImg>
            </RightCol>
            <ColWithMobilePadding xs={12} md={6} lg={5}>
              <ColImg>
                <img src={StellDichQuer4} alt="Stell dich quer by webArtig" />
              </ColImg>
            </ColWithMobilePadding>
          </Row>
        </ContainerWithSpaceBottom> */}
        <ProjectsStrip />
      </div>
      <Footer />
    </Page>
  );
};
