import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../shared/context/app.context";

export const ScrollToTop = () => {
  const history = useHistory();
  const { isFirstTransition, setIsFirstTransition } = useContext(AppContext);

  useEffect(() => {
    const listener = history.listen(() => {
      if (isFirstTransition) {
        setIsFirstTransition(false);
      }

      window.scrollTo(0, 0);
    });
    return () => {
      listener();
    };
  });

  return <></>;
};
