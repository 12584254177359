import { useEffect, useRef } from "react";
import Cursor from "../shared/services/cursor";

export const CustomCursor = () => {
  const cursorInstance = useRef<Cursor>();

  useEffect(() => {
    let cursor: Cursor;
    let nodes: any = [];
    const popStateListener = () => {
      if (cursorInstance.current instanceof Cursor) {
        cursor = cursorInstance.current;

        setTimeout(() => {
          nodes = document.querySelectorAll("a");
          [...nodes].forEach((node) => {
            node.addEventListener("mouseenter", () => cursor.emit("enter"));
            node.addEventListener("mouseleave", () => cursor.emit("leave"));

            if (node.getAttribute("href")) {
              node.addEventListener("click", () => cursor.emit("leave"));
            }
          });
        }, 500);
      }
    };

    window.addEventListener("popstate", popStateListener);
    return () => {
      if (cursor instanceof Cursor) {
        [...nodes].forEach((node) => {
          node.removeEventListener("mouseenter", () => cursor.emit("enter"));
          node.removeEventListener("mouseleave", () => cursor.emit("leave"));

          if (node.getAttribute("href")) {
            node.removeEventListener("click", () => cursor.emit("leave"));
          }
        });
      }

      window.removeEventListener("popstate", popStateListener);
    };
  }, []);

  useEffect(() => {
    const cursor = new Cursor(document.querySelector(".cursor"));
    let nodes: any = [];

    const timeout = setTimeout(() => {
      nodes = document.querySelectorAll("a");
      [...nodes].forEach((el) => {
        el.addEventListener("mouseenter", () => cursor.emit("enter"));
        el.addEventListener("mouseleave", () => cursor.emit("leave"));

        if (el.getAttribute("href")) {
          el.addEventListener("click", () => cursor.emit("leave"));
        }
      });
    }, 500);

    cursorInstance.current = cursor;

    return () => {
      [...nodes].forEach((el) => {
        el.removeEventListener("mouseenter", () => cursor.emit("enter"));
        el.removeEventListener("mouseleave", () => cursor.emit("leave"));

        if (el.getAttribute("href")) {
          el.removeEventListener("click", () => cursor.emit("leave"));
        }
      });

      clearTimeout(timeout);
    };
  }, []);

  return (
    <svg className="cursor" width="280" height="280" viewBox="0 0 280 280">
      <defs>
        <filter
          id="filter-1"
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
          filterUnits="objectBoundingBox"
        >
          <feTurbulence
            type="fractalNoise"
            baseFrequency="0.02 0.15"
            numOctaves="3"
            result="warp"
          />
          <feDisplacementMap
            xChannelSelector="R"
            yChannelSelector="G"
            scale="0"
            in="SourceGraphic"
            in2="warp"
          />
        </filter>
      </defs>
      <circle className="cursor__inner" cx="140" cy="140" r="50" />
    </svg>
  );
};
