import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { calculateFontSize } from "../shared/helper";
import { ContainerWithMaxWidth } from "./ContainerWithMaxWidth";
import { Hyperlink } from "./Hyperlink";

const PortfolioTitle = styled.p`
  width: 100%;
  max-width: 1440px;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 25px;

  span {
    ${calculateFontSize(40, 150)};
  }

  @media screen and (min-width: 768px) {
    margin: 0 auto 50px;
  }
`;

const PortfolioDesc = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Name = styled.span`
  display: inline-flex;
  align-items: center;

  ::after {
    content: "";
    display: inline-block;
    width: 50px;
    height: 1px;
    background-color: #ffffff;
    margin: 0 6px;
  }

  @media screen and (min-width: 468px) {
    ::after {
      width: 80px;
    }
  }
`;

const Title = styled.span`
  display: block;

  @media screen and (min-width: 468px) {
    display: inline-block;
  }
`;

const Nav = styled.nav`
  display: none;

  @media screen and (min-width: 768px) {
    display: inline-block;
  }

  ul {
    li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }
`;

export const Header = () => {
  return (
    <>
      <PortfolioTitle>
        <span>Portfolio</span>
        <span>2021</span>
      </PortfolioTitle>
      <ContainerWithMaxWidth>
        <Row>
          <PortfolioDesc>
            <p>
              <Name>Dennis Politschke</Name>
              <Title>Visual Designer seit 2016</Title>
            </p>
            <Nav>
              <ul>
                <li>
                  <Hyperlink label="Über mich" href="/" />
                </li>
                <li>
                  <Hyperlink label="Projekte" href="/projects" />
                </li>
              </ul>
            </Nav>
          </PortfolioDesc>
        </Row>
      </ContainerWithMaxWidth>
    </>
  );
};
